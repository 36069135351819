<template>
  <article class="submissions">
    <Hero heading="Submissions"> </Hero>
    <section class="l-one__col l-one__col--x-wide">
      <h2 class="headline3">Thank You!</h2>
      <p>Your submission #{{ proposalId }} has been successfully submitted. Please print this page for your records.</p>
      <p>
        Your submission is appreciated and this is notification that your documents have been received. We expect to
        receive up to a thousand or more submissions in just a few months, and these will be reviewed and considered as
        we develop our ZEV Investment Plans. We will contact you if we have clarifying questions regarding your
        submission. Thank you for your input and patience through this process.
      </p>
      <p class="flex-center">
        <img loading="lazy" src="@/assets/images/Submissions/Thanks/ea-logo-dark.png" alt="Electrify America" />
      </p>
    </section>
  </article>
</template>

<script>
import Hero from '../components/Hero/Hero.vue';

export default {
  name: 'submissions-thanks',
  components: { Hero },
  metaInfo: {
    title: 'Submit a comment or recommendation | Electrify America',
    meta: [
      {
        name: 'description',
        content:
          'Have a site suggestion or want to become a vendor? Electrify America wants to hear from you. Fill out the form to let us know your thoughts.',
      },
      { name: 'robots', content: 'noindex' },
    ],
  },
  computed: {
    proposalId() {
      return parseInt(this.$route.params.proposalId, 10);
    },
  },
};
</script>
